body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100dvh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
}

main {
  padding-left: 12px;
  padding-right: 12px;

  @media (min-width: 1024px) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

a {
  color: var(--text-primary);

  &:hover,
  &:focus {
    color: var(--text-primary-hover);
    transform: none;
  }
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.table {
  width: 100%;

  th, td {
    vertical-align: top;
  }

}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}
