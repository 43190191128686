.announcement-new {
  @apply bg-primary-100 text-primary-600;
}

.announcement-fix {
  @apply bg-red-100 text-red-600;
}

.announcement-update {
  @apply bg-green-100 text-green-600;
}

.announcement-improvement {
  @apply bg-purple-100 text-purple-600;
}
