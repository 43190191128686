.dropdown-menu {
  min-width: 100%;

  @media (min-width: 1024px) {
    min-width: 200px;
  }
}

/* Menu component */
nav.menu-component {
  max-width: 275px;
  min-width: 260px;
  background-color: var(--base-bg-low);
  border: 1px solid var(--Base-base-border-tertiary);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;

  @media (min-width: 1024px) {
    min-width: 240px;
  }

  /* Main list */
  > ul.menu-list {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    > li.menu-section {
      margin: 0;
      padding: 8px 0px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--base-border-tertiary);
      }

      /* Section headers */
      > h2.menu-section-heading {
        font-size: 14px;
        color: var(--base-text-tertiary);
        padding: 12px 24px 4px 24px;
        font-weight: 600;
        line-height: 20px;
        margin: 0px;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:has(> a) {
          padding: 0px;
        }

        > a {
          color: var(--base-text-tertiary);
          padding: 12px 24px 4px 24px;
          display: inline-block;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            color: var(--base-text-secondary);
          }
        }
      }

      &:has(> h2.menu-section-heading > a):hover {
        background-color: var(--base-bg-high);
      }

      > ul.menu-sub-list {
        list-style-type: none;
        margin: 0px;
        padding: 0px;

        li {
          padding: 0px 0px;

          a {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 14px 6px 24px;
            color: var(--base-text);
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;

            &:hover {
              background-color: var(--base-bg-high);
              color: var(--base-text-secondary);
            }

            svg {
              color: var(--base-icon);
              width: 20px;
              width: 20px;
            }

            * + svg {
              margin-left: auto;
            }
          }

          form button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 6px 14px 6px 24px;
            color: var(--base-text);
            text-decoration: none;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;

            &:hover {
              background-color: var(--base-bg-high);
              color: var(--base-text-secondary);
            }

            svg {
              color: var(--base-icon);
            }

            * + svg {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

/*Sidebar Component*/
.sidebar {
  width: 350px;
  background-color: var(--base-bg-low);
  padding-top: 16px;

  .btn-container {
    padding: 0px 16px;
  }

  nav {
    padding: 16px 0px;
  }

  h2 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 0;
    color: var(--base-text-tertiary);
    padding: 0px 16px 12px 24px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    > * + * {
      margin-top: 4px;
    }
  }

  nav:not(:first-of-type) {
    border-top: 1px solid var(--base-border-tertiary);
  }

  li {
    padding: 12px 16px 12px 24px;

    &:hover {
      background: var(--base-bg-hover);
    }

    &:has(a.active) {
      background: var(--base-bg-hover);
      position: relative;

      &::before {
        background-color: var(--border-primary);
        bottom: 0;
        content: "";
        width: 4px;
        left: 0;
        position: absolute;
        height: 100%;
      }
    }
  }

  a {
    text-decoration: none;
    color: var(--base-text);
    display: flex;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    align-items: center;

    &:hover {
      color: var(--base-text-secondary);
    }

    &.active {
      color: var(--text-primary);

      .icon {
        color: var(--text-primary);
      }
    }
  }

  .icon {
    margin-right: 8px;
    display: flex;
    align-items: center;
    color: var(--base-icon);

    &:hover {
      color: var(--base-icon);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .cta-btn {
    display: block;
    width: 100%;
    padding: 8px 12px;
    background-color: var(--bg-primary);
    color: var(--text-on-primary);
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
}

/* Sidebar "container" variant */
.sidebar-container {
  border: 1px solid var(--base-border-tertiary);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

/*Vertical Navigation Component*/
.vertical-nav {
  max-width: 270px;
  border-right: 1px solid var(--base-border-tertiary);
  background: var(--base-bg-low);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  height: 100%;

  .logo {
    padding: 32px 24px 16px;
    display: block;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;

    svg {
      color: var(--base-text);
    }
  }
}


.vertical-nav .user-profile {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  position: relative;

  .user-info {
    border: 1px solid var(--base-bg-low);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 4px 8px;

    &:hover {
      border: 1px solid var(--base-border-tertiary);
      background: var(--base-bg-hover);
    }

    > div {
      display: flex;
      align-items: center;
    }

    svg {
      width: 20px;
      height: 20px;
      justify-self: end;
    }

    .avatar {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .user-name {
      color: var(--base-text);
      flex: 1;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .user-email {
      color: var(--base-text-tertiary);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 140px;
    }
  }

  .profile-popup {
    border: 1px solid var(--base-border-tertiary);
    position: absolute;
    left: 40px;
    top: 50px;
    z-index: 1000;
  }
}

.vertical-nav .search-container {
  padding: 16px 24px;
  margin: 0px;
}

.vertical-nav .nav-section {
  padding: 32px 0px 24px 0px;

  &:first-of-type {
    padding: 16px 0px 24px 0px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--base-border-tertiary);
  }

  .section-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--base-text-tertiary);
    padding: 0px 16px 12px 24px;
  }

  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 0;

      svg {
        margin-left: 24px;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        color: var(--base-icon);
      }

      &:has(a.active) {
        background: var(--base-bg-hover);
        position: relative;

        &::before {
          background-color: var(--border-primary);
          bottom: 0;
          content: "";
          width: 4px;
          left: 0;
          position: absolute;
          height: 100%;
        }
      }
    }
  }
}

.vertical-nav .nav-item {
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 0px;
  color: var(--base-text);
  text-decoration: none;
  width: 100%;
  min-width: 0;

  &:hover {
    background: var(--base-bg-hover);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    flex: 1;
  }
}

.left-nav__sub-nav {

  > button {
    color: var(--base-text);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 24px;

    &:hover {
      background: var(--base-bg-hover);
    }

    > div {
      display: flex;
      align-items: center;

      svg {
        color: var(--base-icon);
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    &:focus {
      > svg {
        transform: rotate(180deg);
      }
    }

    > svg {
      height: 20px;
      width: 20px;
      margin-left: 4px;
    }
  }

  .left-nav__sub-nav-dropdown {
    background: var(--base-bg-base);

    ul {
      list-style: none;
      width: 100%;

      a.nav-item {
        display: block;
        padding: 12px 24px 12px 56px;
        color: var(--base-text);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.active {
          color: var(--text-primary);
          font-weight: 600;
        }
      }
    }
  }
}
